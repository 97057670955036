<template>
  <div>
    <PageHeader
      :pageTitle="'Produkt: ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="7" v-if="dataFetched">
        <v-card class="my-2">
          <v-card-title>Produkt</v-card-title>
          <v-card-text>
            <Patcher
              :previewValue="data.id"
              itemName="id"
              itemLabel="ID"
              :hideLabel="false"
              :itemValue="data.id"
              inputElement="input"
              inputType="number"
              selectValueKey=""
              selectTextKey=""
              :isLocked="true"
            ></Patcher>

            <Patcher
              :previewValue="data.name"
              itemName="name"
              itemLabel="Name"
              :hideLabel="false"
              :itemValue="data.name"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              :selectOptions="null"
              selectValueKey=""
              selectTextKey=""
              @emit="data.name = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="type_id"
              itemName="type_id"
              itemLabel="Produkt-Typ"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="select"
              inputType=""
              :selectOptions="productTypes.data.data"
              selectValueKey="id"
              selectTextKey="name"
              @emit="type_id = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.branches"
              itemName="branches"
              itemLabel="Branchen"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="multiselect"
              inputType=""
              selectValueKey="id"
              selectTextKey="name"
              :selectOptions="productBranches.data.data"
              @emit="data.branches = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.categories"
              itemName="categories"
              itemLabel="Kategorien"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="multiselect"
              inputType=""
              :selectOptions="productCategories.data.data"
              selectValueKey="id"
              selectTextKey="name"
              @emit="data.runtimePeriod = $event.value"
            ></Patcher>

            <Patcher
              :previewValue="data.mediaLink"
              itemName="file"
              itemLabel="Produktbild"
              :hideLabel="false"
              :itemValue="data.mediaLink"
              :route="patchRoute"
              inputElement="input"
              inputType="file"
              :selectOptions="null"
              selectValueKey=""
              selectTextKey=""
              :key="componentKey"
              @emit="
                data[$event.name] = $event.value;
                componentKey += 1;
              "
            ></Patcher>

            <Patcher
              :itemValue="data.vimeoId"
              itemName="vimeoId"
              itemLabel="Vimeo Video-ID"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              @emit="data.vimeoId = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.description"
              itemName="description"
              itemLabel="Kurze Beschreibung"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="textarea"
              inputType="text"
              @emit="data.description = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.descriptionLong"
              itemName="descriptionLong"
              itemLabel="Lange Beschreibung"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="textarea-md"
              inputType="text"
              @emit="data.descriptionLong = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.features"
              itemName="features"
              itemLabel="Features"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="textarea"
              inputType="text"
              @emit="data.features = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title>Kosten</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.price"
              itemName="price"
              itemLabel="Preis"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="number"
              @emit="data.price = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.finePrint"
              itemName="finePrint"
              itemLabel="Das 'Kleingedruckte'"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="textarea"
              inputType="text"
              @emit="data.finePrint = $event.value"
            ></Patcher>

            <h3 class="my-4">Einmalige Zusatzkosten</h3>

            <Patcher
              :itemValue="data.feeTitle"
              itemName="feeTitle"
              itemLabel="Bezeichnung"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              @emit="data.feeTitle = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.feePrice"
              itemName="feePrice"
              itemLabel="Preis"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="number"
              @emit="data.feePrice = $event.value"
            ></Patcher>

            <template
              v-if="type_id === '3' || type_id === '4' || type_id === '5'"
            >
              <h3 class="my-4">Vertragliches</h3>

              <Patcher
                :itemValue="data.billingPeriod"
                itemName="billingPeriod"
                itemLabel="Abrechnungszeitraum"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="select"
                inputType=""
                :selectOptions="billingPeriods"
                selectValueKey="id"
                selectTextKey="name"
                :previewValue="periodsTranslation(data.billingPeriod)"
                @emit="data.runtimePeriod = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.runtimeCount"
                itemName="runtimeCount"
                itemLabel="Laufzeit"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="number"
                @emit="data.runtimeCount = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.runtimePeriod"
                itemName="runtimePeriod"
                itemLabel="Laufzeit Periode"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="select"
                inputType=""
                :selectOptions="runtimePeriods"
                selectValueKey="id"
                selectTextKey="name"
                :previewValue="runtimeTranslation(data.runtimePeriod)"
                @emit="data.runtimePeriod = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.renewalAutomatic"
                itemName="renewalAutomatic"
                itemLabel="Automatische Verlängerung"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                @emit="data.renewalAutomatic = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.cancellationCount"
                itemName="cancellationCount"
                itemLabel="Kündigungsfrist Zahl"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="number"
                @emit="data.cancellationCount = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.cancellationPeriod"
                itemName="cancellationPeriod"
                itemLabel="Kündigungsfrist Periode"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="select"
                inputType=""
                :selectOptions="runtimePeriods"
                selectValueKey="id"
                selectTextKey="name"
                :previewValue="runtimeTranslation(data.runtimePeriod)"
                @emit="data.cancellationPeriod = $event.value"
              ></Patcher>
            </template>
          </v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title>Sonstiges</v-card-title>
          <v-card-text>
            <Patcher
              :key="componentKey"
              :itemValue="data.additionalProducts"
              itemName="additionalProducts"
              itemLabel="Verfügbare Zusatzprodukte"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="multiselect"
              inputType=""
              selectValueKey="id"
              selectTextKey="name"
              :selectOptions="additionalProductsOptions.data.data"
              @emit="
                data.additionalProducts = $event.response.additionalProducts;
                componentKey += 1;
              "
            ></Patcher>
          </v-card-text>
        </v-card>

        <!-- tr v-if="type_id === '1'">
                <td class="font-weight-bold">
                  Kategorie (nur klassischer Planer)
                </td>
                <td>
                  <Patcher
                    :itemValue="data.jmpCategories"
                    itemName="jmpCategories"
                    :route="patchRoute"
                    inputElement="multiselect"
                    inputType=""
                    selectValueKey="id"
                    selectTextKey="name"
                    :selectOptions="productJmpCategories.data.data"
                    @emit="data.jmpCategories = $event.value"
                  ></Patcher>
                </td>
              </tr>

              <tr v-if="type_id === '1' || type_id === '2'">
                <td class="font-weight-bold">
                  Preis im Planer (nur klassischer Planer)
                </td>
                <td>
                  <Patcher
                    :itemValue="data.priceJmp"
                    itemName="priceJmp"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="number"
                    @emit="data.priceJmp = $event.value"
                  ></Patcher>
                </td>
              </tr> -->
      </v-col>

      <v-col cols="5" v-if="dataFetched">
        <v-card class="my-2">
          <v-card-title>Meta</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.isActive"
              itemName="isActive"
              itemLabel="Aktiv"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              @emit="data.isActive = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.isDemoRequestable"
              itemName="isDemoRequestable"
              itemLabel="Demo kann angefragt werden"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              @emit="data.isDemoRequestable = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.isDiscountRequestable"
              itemName="isDiscountRequestable"
              itemLabel="Rabatt kann angefragt werden"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              @emit="data.isDiscountRequestable = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.isAdditionalOnly"
              itemName="isAdditionalOnly"
              itemLabel="Nur als Zusatzprodukt verfügbar"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              @emit="data.isAdditionalOnly = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.isJmp"
              itemName="isJmp"
              itemLabel="Im JMP verfügbar"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              @emit="data.isJmp = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.isAdditionalHighlight"
              itemName="isAdditionalHighlight"
              itemLabel="Highlight Zusatzprodukt"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              @emit="data.isAdditionalHighlight = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.previewUrl"
              itemName="previewUrl"
              itemLabel="URL zur Vorschau"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="url"
              @emit="data.previewUrl = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <v-card v-if="data.productType === 'Einzelkampagnen'" class="my-2">
          <v-card-title>Automatisierung</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.hasAutoSetup"
              itemName="hasAutoSetup"
              itemLabel="Atomatisierung verfügbar"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              @emit="data.hasAutoSetup = $event.value"
            ></Patcher>

            <template v-if="data.hasAutoSetup === '1'">
              <Patcher
                :itemValue="data.campaignTitle"
                itemName="campaignTitle"
                itemLabel="Titel der Kampagne"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                @emit="data.campaignTitle = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.campaignBudget"
                itemName="campaignBudget"
                itemLabel="Budget"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="number"
                @emit="data.campaignBudget = $event.value"
              ></Patcher>

              <h3 class="my-2 divider-hl primary darken-2">
                Landingpage-Template
              </h3>

              <Patcher
                :itemValue="data.campaignIsPageless"
                itemName="campaignIsPageless"
                itemLabel="Keine Landingpage"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                hint="Die Kampagne nutzt keine interne Landingpage"
                @emit="data.campaignIsPageless = $event.value"
              ></Patcher>

              <Patcher
                v-if="data.campaignIsPageless !== '1'"
                :itemValue="data.campaignPages_id"
                itemName="campaignPages_id"
                itemLabel="Kampagnentemplate"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="pagepicker"
                inputType=""
                @emit="data.campaignPages_id = $event.value"
              ></Patcher>

              <h3 class="mb-y divider-hl primary darken-2">
                Kategorisierungen
              </h3>

              <Patcher
                :itemValue="data.campaignHasMitfitPay"
                itemName="campaignHasMitfitPay"
                itemLabel="PAY-Kampagne"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                hint="Die Kampagne ist eine mitfitPAY-Kampagne"
                @emit="data.campaignHasMitfitPay = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.campaignHasMitfitMember"
                itemName="campaignHasMitfitMember"
                itemLabel="MEMBER-Kampagne"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                hint="Die Kampagne ist eine mitfitMEMBER-Kampagne. ACHTUNG: Die Dankes-Texte für die E-Mail und die Landingpage werden nicht verwendet."
                @emit="data.campaignHasMitfitMember = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.campaignFacebookLeadAds"
                itemName="campaignFacebookLeadAds"
                itemLabel="Facebook Lead-ADs Kampagne"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                hint="Die Kampagne verwendet Facebook Lead-Ads"
                @emit="data.campaignFacebookLeadAds = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.campaignIsEndless"
                itemName="campaignIsEndless"
                itemLabel="Endlose Laufzeit"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                hint="Die Kampagne läuft endlos"
                @emit="data.campaignIsEndless = $event.value"
              ></Patcher>

              <h3 class="my-2 divider-hl primary darken-2">Quellen</h3>

              <Patcher
                :itemValue="data.campaignAdSource"
                itemName="campaignAdSource"
                itemLabel="Werbequelle"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="select"
                inputType=""
                :previewValue="data.campaignAdSource"
                :selectValueKey="value"
                :selectTextKey="name"
                :selectOptions="adSources"
                @emit="data.campaignAdSource = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.campaignAdSourceCustom"
                itemName="campaignAdSourceCustom"
                itemLabel="Werbequelle Bezeichnung"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                @emit="data.campaignAdSourceCustom = $event.value"
              ></Patcher>

              <template v-if="data.campaignHasMitfitMember !== '1'">
                <h3 class="my-2 divider-hl primary darken-2">
                  Vielen Dank für die Teilnahme
                </h3>

                <p class="red--text font-weight-bold my-2">
                  Beachte: Die Texte gelten NICHT für mitfitMEMBER und
                  mitfitSHOP Kampagnen.
                </p>

                <Patcher
                  :itemValue="data.campaignThanksEmailSubject"
                  itemName="campaignThanksEmailSubject"
                  itemLabel="Betreff der E-Mail"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  @emit="data.campaignThanksEmailSubject = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.campaignThanksEmail"
                  itemName="campaignThanksEmail"
                  itemLabel="Inhalt der E-Mail"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="textarea-md"
                  inputType="text"
                  @emit="data.campaignThanksEmail = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.campaignThanksPage"
                  itemName="campaignThanksPage"
                  itemLabel="Text auf der Seite"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="textarea-md"
                  inputType="text"
                  @emit="data.campaignThanksPage = $event.value"
                ></Patcher>
              </template>

              <h3 class="my-2 divider-hl primary darken-2">Weiterempfehlung</h3>

              <Patcher
                :itemValue="data.campaignRecommendationEnabled"
                itemName="campaignRecommendationEnabled"
                itemLabel="Weiterempfehlung aktiv"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                @emit="data.campaignRecommendationEnabled = $event.value"
              ></Patcher>

              <template v-if="data.campaignRecommendationEnabled === '1'">
                <Patcher
                  :itemValue="data.campaignRecommendationTitle"
                  itemName="campaignRecommendationTitle"
                  itemLabel="Betreff der Weiterempfehlung"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  @emit="data.campaignRecommendationTitle = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.campaignRecommendationText"
                  itemName="campaignRecommendationText"
                  itemLabel="Text Weiterempfehlung"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="textarea-md"
                  inputType="text"
                  @emit="data.campaignRecommendationText = $event.value"
                ></Patcher>
              </template>

              <h3 class="my-2 divider-hl primary darken-2">Feedback</h3>

              <Patcher
                :itemValue="data.campaignFeedbackEnabled"
                itemName="campaignFeedbackEnabled"
                itemLabel="Feedback-Request aktiv"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                @emit="data.campaignFeedbackEnabled = $event.value"
              ></Patcher>

              <template v-if="data.campaignFeedbackEnabled === '1'">
                <Patcher
                  :itemValue="data.campaignFeedbackRequestSubject"
                  itemName="campaignFeedbackRequestSubject"
                  itemLabel="Betreff Feedback-E-Mail"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  @emit="data.campaignFeedbackRequestSubject = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.campaignFeedbackText"
                  itemName="campaignFeedbackText"
                  itemLabel="Text Feedback-E-Mail"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="textarea-md"
                  inputType="text"
                  @emit="data.campaignFeedbackText = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.campaignFeedbackRequestAfterDays"
                  itemName="campaignFeedbackRequestAfterDays"
                  itemLabel="Tage bis Feedback-Request"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="number"
                  @emit="data.campaignFeedbackRequestAfterDays = $event.value"
                ></Patcher>
              </template>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "Productdetail",

  components: {
    PageHeader,
    Patcher,
  },

  data() {
    return {
      data: [],
      componentKey: 0,
      dataFetched: false,
      patchRoute: "products/" + this.$route.params.id,
      pageTitle: "",
      pageDescription: "Hier kannst du die Daten des Produktes bearbeiten.",
      type_id: null,
      productTypes: null,
      productBranches: [],
      productCategories: null,
      productJmpCategories: null,
      additionalProductsOptions: null,
      adSources: [
        {
          name: "Other",
          value: "Other",
        },
        {
          name: "Facebook Ads",
          value: "Facebook Ads",
        },
      ],
      runtimePeriods: [
        {
          name: "",
          id: "",
        },
        {
          name: "Tage",
          id: "days",
        },
        {
          name: "Wochen",
          id: "weeks",
        },
        {
          name: "Monate",
          id: "months",
        },
        {
          name: "Quartals",
          id: "quarters",
        },
        {
          name: "Halbjahre",
          id: "halfYears",
        },
        {
          name: "Jahre",
          id: "years",
        },
      ],

      billingPeriods: [
        {
          name: "",
          id: "",
        },
        {
          name: "Einmalig",
          id: "once",
        },
        {
          name: "Täglich",
          id: "daily",
        },
        {
          name: "Wöchentlich",
          id: "weekly",
        },
        {
          name: "Monatlich",
          id: "monthly",
        },
        {
          name: "Alle drei Monate",
          id: "quarterly",
        },
        {
          name: "Halbjährlich",
          id: "halfYearly",
        },
        {
          name: "Jährlich",
          id: "yearly",
        },
      ],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      // GET PRODUCTS
      this.additionalProductsOptions = await this.getRequest("products"); // await data from mixin

      // GET PRODUCT-TYPES
      this.productTypes = await this.getRequest("products/types"); // await data from mixin

      // GET PRODUCT-CATGORIES
      this.productCategories = await this.getRequest("products/categories"); // await data from mixin

      // GET PRODUCT-BRANCHES
      this.productBranches = await this.getRequest("products/branches"); // await data from mixin

      // GET PRODUCT-JMP-CATEGORIES (FOR JMP ONLY)
      this.productJmpCategories = await this.getRequest(
        "products/jmpCategories"
      ); // await data from mixin

      // GET PRODUCT-DATA
      let response = await this.getRequest("products/" + this.$route.params.id); // await data from mixin
      this.data = response.data.data;
      this.pageTitle = this.data.name;
      this.type_id = this.data.type_id;

      this.dataFetched = true;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>

<style>
.v-application td ol,
.v-application td ul {
  margin: 20px 0 !important;
}

.v-application td p {
  margin-bottom: 12px;
  margin-top: 12px;
}
</style>
